import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //---------PreferedJobRole---------
  prefRole: [],
  userid: "",

  //----------Current Location Paage ---------
  job_location: {
    id: "",
    city: "",
    state: "",
    country: "",
    code: "",
  },
  nationality: {
    id: "",
    name: "",
    nationality: "",
    code: "",
  },
  openToReloc: false,
  is_international: false,

  //----------job Prefrence  page----------
  jobPref: [],
  empMode: [],

  //----------Salary pref page ----------
  id: "",
  salaryType: "annually",
  currency: "INR",
  currencyId: "",
  curSalary: "",
  expSalary: "",
  noticePeriod: 0,
  languages: [],
  isExpereinced: false,
  isFresher: false,
  IsMonthly: false,

  //----------Status of job  ----------
  seekPref: "",
  dob: "",
  gender: "",
  phone: {
    id: null,
    cid: "",
    phone_number: "",
    country_code: "",
  },
};

const postjobPrefrenceseSlice = createSlice({
  name: "postJobPrefrence",
  initialState,
  reducers: {
    setPostJobPrefrences: (state, action) => {
      return action.payload;
    },
    setPreferedJobRole: (state, action) => {
      state.prefRole = action.payload.prefRole;
      state.userid = action.payload.userid;
    },
    setPrefLocation: (state, action) => {
      state.job_location = action.payload.job_location;
      state.openToReloc = action.payload.openToReloc;
      state.is_international = action.payload.is_international;
      state.phone = action.payload.phone;
      state.gender = action.payload.gender;
      state.dob = action.payload.dob;
      state.nationality = action.payload.nationality
    },
    setJobPreference: (state, action) => {
      state.jobPref = action.payload.jobPref;
      state.empMode = action.payload.empMode;
    },
    setSalaryPref: (state, action) => {
      state.id = action.payload.id;
      state.salaryType = action.payload.salaryType;
      state.noticePeriod = action.payload.noticePeriod;
      state.languages = action.payload.languages;
      state.currency = action.payload.currency;
      state.currencyId = action.payload.currencyId;
      state.curSalary = action.payload.curSalary;
      state.expSalary = action.payload.expSalary;
      state.isExpereinced = action.payload.isExpereinced;
      state.isFresher = action.payload.isFresher;
      state.IsMonthly = action.payload.IsMonthly;
    },
    setSeekingPref: (state, action) => {
      state.seekPref = action.payload.seekPref;
      state.dob = action.payload.dob;
      state.phone = action.payload.phone;
      state.gender = action.payload.gender;
      
    },
    clearPrefData: (state) => {
      state.prefRole = [];
      state.userid = "";

      //----------Current Location Paage ---------
      state.job_location = {
        id: "",
        city: "",
        state: "",
        country: "",
      },
      state.openToReloc = false;
      state.is_international = false;
      state.gender = "";
      state.dob = "";
      state.nationality = {
        id: "",
        name: "",
        nationality: "",
        code: "",
      },

      //----------job Prefrence  page----------
      state.jobPref = [];
      state.empMode = [];

      //----------Salary pref page ----------
      state.id = "";
      state.salaryType = "";
      state.currency = "";
      state.currencyId = "";
      state.curSalary = "";
      state.expSalary = "";
      state.noticePeriod = 0;
      state.languages = [];
      state.isExpereinced = false;
      state.isFresher = false;

      //----------Status of job  ----------
      state.seekPref = "";
    },
  },
});

export const {
  setPostJobPrefrences,
  setPreferedJobRole,
  setPrefLocation,
  setJobPreference,
  setSalaryPref,
  setSeekingPref,
  clearPrefData, // Updated action name here
} = postjobPrefrenceseSlice.actions;
export const selectPostJobPrefrence = (state) => state.postJobPrefrence;
export default postjobPrefrenceseSlice.reducer;
